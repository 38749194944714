import React from 'react';
import './App.css';
import Registration from './Registration'

function App() {
  return (
    <div>
      <Registration />
      <footer>2019 © Maryam Kordi. All rights reserved.</footer>
    </div>
  );
}

export default App;
