import React, { Component } from 'react';

class Registration extends Component {
  constructor(props){
    super(props)
    this.state = {
      calli: '',
      illu: '',
      displayErrors: false,
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!e.target.checkValidity()) {
      this.setState({ displayErrors: true });
      return;
    }
    this.setState({ displayErrors: false });
    const data = new FormData(e.target);
    
    fetch('https://www2.maryamk.net/register.php', {
      method: 'POST',
      body: data,
    }).then((d) => {
      window.location.href = "https://www2.maryamk.net/registration-confirmation-en.html";
    })
  }

  onCalliChange(e){
    this.setState({
      calli: e.currentTarget.value
    })
  }

  onIlluChange(e){
    this.setState({
      illu: e.currentTarget.value
    })
  }

  calliPrice(){
    let reg = this.state.calli
    if ((this.state.illu === 'each-course' || 
         this.state.illu === 'every-second-course') && 
        (this.state.calli === 'each-course' ||
          this.state.calli === 'every-second-course')){
      reg += '-reduced'
    }
    const p = {
      'each-course': '40 CHF / session',
      'each-course-reduced': '34 CHF / session',
      'every-second-course': '56 CHF / session',
      'every-second-course-reduced': '48 CHF / session',
      'occasionally': '70 CHF / session'
    }
    return p[reg] || '0 CHF'
  }

  illuPrice(){
    let reg = this.state.illu
    if ((this.state.illu === 'each-course' || 
         this.state.illu === 'every-second-course') && 
        (this.state.calli === 'each-course' ||
          this.state.calli === 'every-second-course')){
      reg += '-reduced'
    }
    const p = {
      'each-course': '35 CHF / session',
      'each-course-reduced': '30 CHF / session',
      'every-second-course': '49 CHF / session',
      'every-second-course-reduced': '42 CHF / session',
      'occasionally': '60 CHF / session'
    }
    return p[reg] || '0 CHF'
  }

  render(){
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3>Registration</h3>
            <p>I would like to register for Iranian/Persian Calligraphy / illumination (Tazhib) courses 2019 by Maryam Kordi:</p>

            <form 
              onSubmit={this.handleSubmit.bind(this)} 
              noValidate
              className={this.state.displayErrors ? 'displayErrors' : ''} >
               <div className="form-group">
                <label htmlFor="name">Name and family name</label>
                <input type="text" className="form-control" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="street">Street / number</label>
                <input type="text" className="form-control" name="street" required />
              </div>
              <div className="form-group">
                <label htmlFor="place">ZIP / place</label>
                <input type="text" className="form-control" name="place" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input type="email" className="form-control" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone number</label>
                <input type="text" className="form-control" name="phone" required />
              </div>

              <hr />

              <p><b>Persian calligraphy course</b></p>

              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="calli" 
                  id="calli1" 
                  onChange={this.onCalliChange.bind(this)}
                  checked={this.state.calli === 'each-course'}
                  value='each-course' />
                <label className="form-check-label" htmlFor="calli1">
                  Registration for regular base attendance to every session (every 2 weeks)
                </label>
              </div>
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="calli" 
                  id="calli2" 
                  onChange={this.onCalliChange.bind(this)}
                  checked={this.state.calli === "every-second-course"}
                  value='every-second-course' />
                <label className="form-check-label" htmlFor="calli2">
                  Registration for regular base attendance every second session (once a month)
                </label>
              </div>
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="calli" 
                  id="calli3" 
                  onChange={this.onCalliChange.bind(this)}
                  checked={this.state.calli === "occasionally"}
                  value="occasionally" />
                <label className="form-check-label" htmlFor="calli3">
                  Registration for occasional attendance in the course
                </label>
              </div>
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="calli" 
                  id="calli0" 
                  onChange={this.onCalliChange.bind(this)}
                  checked={this.state.calli === "no-registration"}
                  value="no-registration" />
                <label className="form-check-label" htmlFor="calli0">
                  I don't want to register for the calligraphy course
                </label>
              </div>

              <p style={{marginTop: 15}}>Cost for Persian calligraphy course: {this.calliPrice()}</p>

              <hr />

              <p><b>Persian illumination course</b></p>

              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="illu" 
                  id="illu1" 
                  onChange={this.onIlluChange.bind(this)}
                  checked={this.state.illu === "each-course" }
                  value="each-course" />
                <label className="form-check-label" htmlFor="illu1">
                  Registration for regular base attendance to every session (every 2 weeks)
                </label>
              </div>
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="illu" 
                  id="illu2" 
                  onChange={this.onIlluChange.bind(this)}
                  checked={this.state.illu === "every-second-course"}
                  value="every-second-course" />
                <label className="form-check-label" htmlFor="illu2">
                  Registration for regular base attendance every second session (once a month)
                </label>
              </div>
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="illu" 
                  id="illu3" 
                  onChange={this.onIlluChange.bind(this)}
                  checked={this.state.illu === "occasionally"}
                  value="occasionally" />
                <label className="form-check-label" htmlFor="illu3">
                  Registration for occasional attendance in the course
                </label>
              </div>
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="illu" 
                  id="illu0" 
                  onChange={this.onIlluChange.bind(this)}
                  checked={this.state.illu === "no-registration"}
                  value="no-registration" />
                <label className="form-check-label" htmlFor="illu0">
                  I don't want to register for the illumination course
                </label>
              </div>

              <p style={{marginTop: 15}}>Cost for Persian illumination course: {this.illuPrice()}</p>
              
              <hr />

              <div className="form-group">
                <label htmlFor="msg">Additional message or comment</label>
                <textarea className="form-control" name="msg"></textarea>
              </div>

              <hr />

              <p style={{fontSize: '85%'}}>After registration, you will get instructions for payment by email. The registration is finalised only after the payment.<br/>

              Important: the course only takes place if a minimum of 4 people are registered. There is a limited number of places.<br/>

              Absences of students in the registered classes cannot be refunded. However the missed materials will be teached to the student in the upcoming sessions. In the case of emergency cancelations of the class from the teacher side, a replacement class will be scheduled or the session will be refunded.</p>

              <hr />
              <button className="btn btn-primary">Finish Registration</button>
            </form>
          </div>
        </div>
      </div>
    )
  }

}

export default Registration
